import "./App.css";
import "react-h5-audio-player/lib/styles.css";

import React, { useEffect, useState, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import { FilesetResolver, GestureRecognizer } from "@mediapipe/tasks-vision";

import ely_1 from "./assets/ely_1.mp3";
import ely_2 from "./assets/ely_2.mp3";
import breath_in from "./assets/breath_in.png";
import breath_out from "./assets/breath_out.png";

const playlist = [
  {
    name: "You Are My Sunshine",
    src: ely_1,
  },
  {
    name: "Kawaii",
    src: ely_2,
  },
];
const PlayList = () => {
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);

  const handleClickPrevious = () => {
    setCurrentMusicIndex((prevState) =>
      prevState === 0 ? playlist.length - 1 : prevState - 1
    );
  };

  const handleClickNext = () => {
    setCurrentMusicIndex((prevState) =>
      prevState < playlist.length - 1 ? prevState + 1 : 0
    );
  };

  return (
    <div className="playlist">
      <AudioPlayer
        autoPlay
        onEnded={handleClickNext}
        autoPlayAfterSrcChange={true}
        showSkipControls={true}
        showJumpControls={false}
        src={playlist[currentMusicIndex].src}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
      />
    </div>
  );
};
const SecuritAuth = ({ onLove }) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let gestureRecognizer;
    let animationFrameId;
    let isDetecting = true;
    let loveDetectedCount = 0;

    const initializeHandDetection = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
      );
      gestureRecognizer = await GestureRecognizer.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath:
            "https://pwrstuff.s3.eu-central-1.amazonaws.com/gesture_recognizer.task",
          delegate: "GPU",
        },
        runningMode: "VIDEO",
      });
      setIsLoading(false);
      detectHands();
    };

    const detectHands = () => {
      if (!isDetecting) return;
      if (videoRef.current && videoRef.current.readyState >= 2) {
        if (!gestureRecognizer) {
          alert("Please wait for gestureRecognizer to load");
          return;
        }
        const detections = gestureRecognizer.recognizeForVideo(
          videoRef.current,
          performance.now()
        );
        if (detections.gestures.length > 0) {
          const categoryName = detections.gestures[0][0].categoryName;
          const categoryScore = parseFloat(
            detections.gestures[0][0].score * 100
          ).toFixed(2);

          if (categoryName === "love" && categoryScore > 90) {
            loveDetectedCount++;
            if (loveDetectedCount >= 5) {
              isDetecting = false;
              if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject
                  .getTracks()
                  .forEach((track) => track.stop());
                videoRef.current.srcObject = null;
              }
              if (gestureRecognizer) {
                gestureRecognizer.close();
              }
              if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
              }
              onLove();
            }
          } else {
            loveDetectedCount = 0;
          }
        }
      }
      setTimeout(() => requestAnimationFrame(detectHands), 100);
    };

    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = stream;
        await initializeHandDetection();
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    };

    startWebcam();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
      if (gestureRecognizer) {
        gestureRecognizer.close();
      }
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <h1>Unlock with love</h1>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={{ width: "100%", height: "auto" }}
        ></video>
      </div>
      <div className="loader" style={{ display: isLoading ? "flex" : "none" }}>
        <h1 className="content">Loading...</h1>
      </div>
    </>
  );
};

const Breath = () => {
  return (
    <main>
      <div className="wrapper">
        <div className="circle"></div>
        <p className="in">
          <img src={breath_in} alt="" />
          Breath In
        </p>
        <p className="out">
          <img src={breath_out} alt="" />
          Breath Out
        </p>
      </div>
      <PlayList />
    </main>
  );
};

const App = () => {
  const [lovePresence, setLovePresence] = useState(false);

  if (lovePresence) {
    return <Breath />;
  }

  return (
    <SecuritAuth
      onLove={() => {
        setLovePresence(true);
      }}
    />
  );
};
export default App;
